import React, { FC } from 'react';
import classNames from 'classnames';

import { Link } from 'gatsby';
import Image from 'common/Image';
import { ImageTextBannerProps } from './models';

import './ImageTextBanner.scss';

const ImageTextBanner: FC<ImageTextBannerProps> = ({
  item: {
    image,
    url: [url],
  },
}) => (
  <div className={classNames('image-text__banner')}>
    <Link to={url.url} className="image-text__link">
      <Image
        alt={url.name || 'Banner image'}
        imageData={image}
        className="image-text__banner-image"
      />
      <div className="image-text__banner-overlay">
        <span className="image-text__banner-text">{url.name}</span>
      </div>
    </Link>
  </div>
);

export default ImageTextBanner;
