import React, { FC } from 'react';

import { graphql } from 'gatsby';

import { ImageTextListProps } from './models';
import './ImageTextList.scss';
import ImageTextItem from './ImageTextItem';

const ImageTextList: FC<ImageTextListProps> = ({ items, stepString = 'step' }) => (
  <div className="image-text">
    {items.map(({ properties }, index) => (
      <ImageTextItem
        // eslint-disable-next-line react/no-array-index-key
        key={`image-text-item-${index}`}
        item={properties}
        stepString={stepString}
        counter={index + 1}
      />
    ))}
  </div>
);

export const query = graphql`
  fragment ImageTextListFragment on ImageTextList {
    properties {
      items {
        properties {
          title
          description
          image {
            ...ImageFragment
          }
          banners {
            properties {
              image {
                ...ImageFragment
              }
              url {
                url
                name
              }
            }
          }
        }
      }
      stepString
    }
  }
`;

export default ImageTextList;
