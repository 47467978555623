import React, { FC } from 'react';
import classNames from 'classnames';

import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import Image, { ImageBreakpoint } from 'common/Image';
import ImageTextBanner from '../ImageTextBanner';
import { ImageTextItemProps } from './models';

import './ImageTextItem.scss';

const ImageTextItem: FC<ImageTextItemProps> = ({
  item: { banners, description, image, title },
  counter,
  stepString,
}) => {
  const isRightSide = counter % 2 === 0;
  const twoDigitCounter = counter.toString().padStart(2, '0');
  const breakpoints: ImageBreakpoint[] = [
    { alias: 'mobile', media: '(max-width: 768px)' },
    { alias: 'tablet', media: '(max-width: 992px)' },
  ];

  return (
    <div className={classNames('image-text__item', { 'right-side': isRightSide })}>
      <div className="image-text__content">
        <div className={classNames('image-text__header', { 'flip-padding': isRightSide })}>
          <SectionName text={`${stepString} ${twoDigitCounter}`} />
          <SectionTitle text={title} />
          <span className="image-text__counter">{twoDigitCounter}</span>
        </div>
        <p className={classNames('image-text__description', { 'flip-padding': isRightSide })}>
          {description}
        </p>
      </div>
      <div className="image-text__image">
        <Image imageData={image} alt={title} breakpoints={breakpoints} />
      </div>
      <div
        className={classNames('image-text__banners', {
          'image-text__banners--more-items': banners.length > 3,
        })}
      >
        {banners.map(({ properties }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ImageTextBanner key={index} item={properties} />
        ))}
      </div>
    </div>
  );
};

export default ImageTextItem;
