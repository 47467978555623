import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import Image from 'common/Image';

import RelatedProducts from 'components/RelatedProducts';
import ImageTextList from 'components/ImageTextList';
import './LearnPage.scss';
import Video from 'components/Video';
import FaqAccordion from 'components/Faq/FaqAccordion';
import { LearnPageProps } from './models';

const LearnPage: FC<LearnPageProps> = ({
  pageContext: { areBreadcrumbsDisplayed, breadcrumb },
  data: {
    allLearn: {
      nodes: [learn],
    },
    allSitePage: { nodes: breadcrumbNodes },
  },
  location: { pathname },
}) => {
  const {
    name,
    headerImage,
    imageTextList,
    video: [{ properties: videoProps }],
    productsFinder: [{ properties: productsFinderData }],
    faqSection: [{ properties: faqData }],
  } = learn;
  const breadcrumbs = createBreadcrumbs(
    breadcrumb.crumbs,
    areBreadcrumbsDisplayed,
    breadcrumbNodes,
    pathname
  );
  const [
    {
      properties: { stepString, items },
    },
  ] = imageTextList;

  return (
    <Layout className="learn-page" seo={learn} breadcrumbs={breadcrumbs}>
      <div className="header-section">
        <Image imageData={headerImage} alt={name} className="header-section__image" />
      </div>
      <ImageTextList items={items} stepString={stepString} />
      <Video {...videoProps} />
      <FaqAccordion {...faqData} />
      <RelatedProducts
        className="learn-page__products-finder"
        sectionLight={false}
        {...productsFinderData}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allLearn {
      nodes {
        ...SeoFragment
        id
        name
        headerImage {
          childImageSharp {
            gatsbyImageData(width: 600, placeholder: BLURRED)
          }
        }
        imageTextList {
          ...ImageTextListFragment
        }
        video {
          ...VideoFragment
        }
        productsFinder {
          ...RelatedProductsFragment
        }
        faqSection {
          ...FaqAccordionFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default LearnPage;
